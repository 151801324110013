<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    type?: "primary" | "secondary" | "tertiary";
  }>(),
  {
    type: "primary",
  }
);
const styles = computed(() => ({
  "tag-primary": props.type === "primary",
  "tag-secondary": props.type === "secondary",
  "tag-tertiary": props.type === "tertiary",
}));
</script>
<template>
  <div class="tag" :class="styles">
    <slot></slot>
  </div>
</template>
<style scoped></style>
